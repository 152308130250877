import React, { useState } from "react";
import Cards from "./components/Cards";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Image from "./imgs/DSC_8355.jpg";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Roboto",
      fontSize: window.innerWidth > 1000 ? 100 : 20,
    },
  },
});

const theme2 = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Roboto",
      fontSize: window.innerWidth > 1000 ? 16 : 10,
    },
  },
});

const theme3 = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Roboto",
      fontSize: 12,
    },
  },
});

const theme4 = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Roboto",
      fontSize: 20,
    },
  },
});

const theme5 = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Roboto",
      fontSize: 16,
    },
  },
});

const backgroundStyle = {
  minHeight: "100vh",
  backgroundImage: `url(${Image})`,

  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

//<img
//src="https://studio.nmkr.io/images/buttons/paybutton_1_3.svg"
//onClick={() => openPaymentWindow()}
///>
function openPaymentWindow() {
  const paymentUrl =
    "https://pay.nmkr.io/?p=d29d5eee115c4e788141365c1f4fcb12&c=1";

  // Specify the popup width and height
  const popupWidth = 500;
  const popupHeight = 700;

  // Calculate the center of the screen
  const left = window.top.outerWidth / 2 + window.top.screenX - popupWidth / 2;
  const top = window.top.outerHeight / 2 + window.top.screenY - popupHeight / 2;

  const popup = window.open(
    paymentUrl,
    "NFT-MAKER PRO Payment Gateway",
    `popup=1, location=1, width=${popupWidth}, height=${popupHeight}, left=${left}, top=${top}`
  );

  // Show dim background
  document.body.style = "background: rgba(0, 0, 0, 0.5)";

  // Continuously check whether the popup has been closed
  const backgroundCheck = setInterval(function () {
    if (popup.closed) {
      clearInterval(backgroundCheck);

      console.log("Popup closed");

      // Remove dim background
      document.body.style = "";
    }
  }, 1000);
}

export default function App() {
  const [route, setRoute] = useState("home");

  if (route == "home") {
    return (
      <React.Fragment>
        <CssBaseline />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap"
          rel="stylesheet"
        />
        <div style={backgroundStyle}>
          <Button
            variant="outlined"
            sx={{ color: "black", border: 0 }}
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
              position: "absolute",
              left: "44px",
              top: "44px",
              height: "88px",
              width: "88px",
              borderRadius: 40,
            }}
            onClick={(e) => {
              setRoute("home");
            }}
          >
            <img
              style={{
                position: "absolute",
                height: "88px",
                width: "88px",
                borderRadius: 40,
              }}
              src={require("./imgs/logoTrans.png")}
            />
          </Button>

          <Button
            variant="outlined"
            sx={{ color: "black", border: 0 }}
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
              position: "absolute",
              left: "150px",
              top: "60px",
            }}
            onClick={(e) => {
              setRoute("about");
            }}
          >
            <ThemeProvider theme={theme5}>
              <Typography
                variant="overline"
                gutterBottom
                style={{ marginTop: "4px" }}
              >
                About/Contact
              </Typography>
            </ThemeProvider>
          </Button>

          <Box
            display="flex"
            style={{
              minHeight: "100vh",
            }}
            sx={{
              flexGrow: 1,
              flexDirection: window.innerWidth > 1000 ? "row" : "column",
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: window.innerWidth > 1000 ? 0 : 180 }}
              sx={{ flexGrow: 1, flexDirection: "column" }}
            >
              <ThemeProvider theme={theme}>
                <Typography variant="h1" gutterBottom>
                  ANACHR0NISMS: I
                </Typography>
              </ThemeProvider>

              <Button
                variant="outlined"
                sx={{ color: "black" }}
                style={{
                  borderRadius: 8,
                  borderColor: "#deb1ce",
                  backgroundColor: "#fdeef7",
                  padding: "2px 25px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href =
                    "https://jpg.store/collection/anachr0nisms-i";
                }}
              >
                <ThemeProvider theme={theme2}>
                  <Typography
                    variant="overline"
                    gutterBottom
                    style={{ marginTop: "4px" }}
                  >
                    View Collection
                  </Typography>
                </ThemeProvider>
              </Button>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ margin: 20 }}
              sx={{ flexGrow: 1 }}
            >
              <Cards />
            </Box>
          </Box>
        </div>
      </React.Fragment>
    );
  }
  if (route == "about") {
    return (
      <React.Fragment>
        <CssBaseline />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap"
          rel="stylesheet"
        />
        <div style={backgroundStyle}>
          <Button
            variant="outlined"
            sx={{ color: "black", border: 0 }}
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
              position: "absolute",
              left: "44px",
              top: "44px",
              height: "88px",
              width: "88px",
              borderRadius: 40,
            }}
            onClick={(e) => {
              setRoute("home");
            }}
          >
            <img
              style={{
                position: "absolute",
                height: "88px",
                width: "88px",
                borderRadius: 40,
              }}
              src={require("./imgs/logoTrans.png")}
            />
          </Button>

          <Button
            variant="outlined"
            sx={{ color: "black", border: 0 }}
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
              position: "absolute",
              left: "150px",
              top: "60px",
            }}
            onClick={(e) => {
              setRoute("about");
            }}
          >
            <ThemeProvider theme={theme5}>
              <Typography
                variant="overline"
                gutterBottom
                style={{ marginTop: "4px" }}
              >
                About/Contact
              </Typography>
            </ThemeProvider>
          </Button>

          {/* Body */}
          <Box
            display="flex"
            style={{
              minHeight: "100vh",
            }}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ flexGrow: 1, flexDirection: "column" }}
              style={{
                backgroundColor: "rgba(253, 238, 247, 0.8)",
                margin: 44,
                marginTop: 200,
                borderRadius: 12,
                padding: 44,
              }}
            >
              <ThemeProvider theme={theme4}>
                <Typography
                  variant="overline"
                  gutterBottom
                  style={{ marginTop: "4px" }}
                >
                  About
                </Typography>
              </ThemeProvider>

              <ThemeProvider theme={theme3}>
                <Typography variant="overline" gutterBottom>
                  K. Li: Anachr0nisms | Illustration and animation.
                </Typography>

                <Typography variant="overline" gutterBottom>
                  Exploring use of dynamic illustration and composition as a
                  conductor for body sense memory and emotion. Past and present
                  work interested in combining familiarity and discomfort to
                  access personal emotions and memories stored within the
                  uncanny valley. The Anachr0nisms project was developed to
                  showcase personal work, and will continue to mutate alongside
                  it. Please enjoy your stay.
                </Typography>
              </ThemeProvider>

              <ThemeProvider theme={theme4}>
                <Typography
                  variant="overline"
                  gutterBottom
                  style={{ marginTop: "4px" }}
                >
                  Contact
                </Typography>
              </ThemeProvider>

              <ThemeProvider theme={theme3}>
                <Typography variant="overline" gutterBottom>
                  For commission, project discussion and other business-related
                  inquiries, please contact{" "}
                  <a href="mailto:anachr0nisms.v1@gmail.com">
                    anachr0nisms.v1@gmail.com
                  </a>
                  .
                </Typography>
              </ThemeProvider>
            </Box>
          </Box>

          {/* Body end  */}
        </div>
      </React.Fragment>
    );
  }
}
