import * as React from "react";
import Card from "@mui/material/Card";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCube, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import "../css/App.css";

export default function Cards(props) {
  const imgStyle = {
    width: window.innerWidth > 1000 ? 1668 / 4 : (1668 / 4) * 0.8,
    height: window.innerWidth > 1000 ? 2388 / 4 : (2388 / 4) * 0.8,
  };

  return (
    <React.Fragment>
      <Swiper
        effect={"cube"}
        grabCursor={true}
        cubeEffect={{
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94,
        }}
        pagination={false}
        modules={[EffectCube, Pagination]}
        style={imgStyle}
      >
        <SwiperSlide>
          <Card>
            <img style={imgStyle} src={require("../imgs/image3.png")} />
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card>
            <img style={imgStyle} src={require("../imgs/image4.png")} />
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card>
            <img style={imgStyle} src={require("../imgs/image2.png")} />
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card>
            <img style={imgStyle} src={require("../imgs/image1.png")} />
          </Card>
        </SwiperSlide>
        <SwiperSlide>
          <Card>
            <img style={imgStyle} src={require("../imgs/image0.png")} />
          </Card>
        </SwiperSlide>
      </Swiper>
    </React.Fragment>
  );
}
